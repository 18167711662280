<template>
    <div class="card">
		<DataTable :value="products" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
			:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
			:rowsPerPageOptions="[5, 10, 20, 50]"  scrollHeight="400px">
			<template #header>
				<div class="table-header">
					<div>{{$t('Records')}}: {{ products.length }}</div>
					<div>
						<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New" v-if="insert"/>
					</div>
				</div>
			</template>
			<Column field="score" :header="$t('Score')" headerStyle="width: 5%"/>
			<Column field="name" :header="$t('Level')" headerStyle="width: 10%" >
				<template #body="slotProps" >
					<Tag :style="'width: 100%; height: 30px;color:#'+slotProps.data.colortext+';background-color: #'+slotProps.data.color">
						{{slotProps.data[i18n.locale()]}}
					</Tag>
				</template>
			</Column>
			<Column field="name" :header="$t('Description')">
				<template #body="slotProps">
					{{slotProps.data['description_'+i18n.locale()]}}
				</template>
			</Column>
			<Column :header="$t('Status')" headerStyle="width: 10%">
				<template #body="slotProps">
					<span class="p-tag p-tag-info"  v-if="slotProps.data.activo">{{$t('Asset')}}</span>
					<span class="p-tag p-tag-info"  v-else>{{$t('Inactive')}}</span>
				</template>
			</Column>
			<Column :header="$t('Actions')" headerStyle="width: 10%">
				<template #body="slotProps">
					<Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-mr-2" @click="Edit(slotProps.data)" v-if="edit"  v-tooltip.bottom="$t('Edit')"/>
					<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="confirmDeleteProduct(slotProps.data)" v-if="del"  v-tooltip.bottom="$t('Delete')"/>
				</template>
			</Column>
		</DataTable>
		<Dialog v-model:visible="productDialog" :style="{width: size}" :header="$t('Edit')" :modal="true" class="p-fluid" :maximizable="true">
			<br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-12 p-md-5 p-lg-5">
						<label for="name">{{$t('Level')}} {{ $t('English') }}</label>
						<InputText id="name" v-model="product.en" required="true" :placeholder="$t('English')"/>
					</div>
					<div class="p-col-12 p-md-5 p-lg-5">
						<label for="name">{{$t('Level')}} {{ $t('Spanish') }}</label>
						<InputText id="name" v-model="product.es" required="true" :placeholder="$t('Spanish')"/>
					</div>
					<div class="p-col-6 p-md-2 p-lg-2">
						<label for="name">{{$t('Example')}}</label>
						<Tag :style="'width: 100%; height: 30px;color:#'+product.colortext+';background-color: #'+product.color">
							{{product[i18n.locale()]}}
						</Tag>
					</div>
					<div class="p-col-12 p-md-12 p-lg-12">
						<label for="name">{{$t('Descriptionl')}} {{ $t('English') }}</label>
						<Textarea v-model="product.description_en" rows="3" autoResize/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-12">
						<label for="name">{{$t('Description')}} {{ $t('Spanish') }}</label>
						<Textarea v-model="product.description_es" rows="3" autoResize/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-2">
						<label for="icon">{{$t('Score')}}</label>
						<InputNumber v-model="product.score" min="0"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-2">
						<label for="icon">{{$t('Color')}}</label>
						<ColorPicker  v-model="product.color"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-2">
						<label for="icon">{{$t('HEX')}}</label>
						<InputText id="name" v-model="product.color" required="true"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-2">
						<label for="icon">{{$t('Text')}}</label>
						<ColorPicker  v-model="product.colortext"/>
					</div>
					<div class="p-col-12 p-md-12 p-lg-2">
						<label for="icon">{{$t('HEX')}}</label>
						<InputText id="name" v-model="product.colortext" required="true"/>
					</div>
					<div class="p-col-6 p-md-2 p-lg-2">
						<h6 style="margin-top: 0">{{$t('Status')}}</h6>
						<InputSwitch v-model="product.activo" />
					</div>
				</div>
			</div>
			<template #footer>
				<Button :label="$t('Save')" icon="pi pi-check" class="p-button-secondary" @click="Save()" />
				<Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="productDialog = false"/>
			</template>
		</Dialog>
		<Dialog v-model:visible="deleteProductDialog" :style="{width: size}"  :header="$t('Confirm')" :modal="true">
			<br>
            <div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">{{$t('Are you sure you want to delete?')}}</span>
			</div>
			<template #footer>
				<Button :label="$t('Yes')" icon="pi pi-check" class="p-button-secondary" @click="Delete" />
				<Button :label="$t('No')" icon="pi pi-times" class="p-button-secondary" @click="deleteProductDialog = false"/>
			</template>
		</Dialog>
	</div>
</template>

<script>
	import API from "../service/API";
	var Consult = new API('Parameters','Scoring');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		props: {
			c_insert: {
				type: Boolean,
				default: false,
			},
			c_edit: {
				type: Boolean,
				default: false,
			},
			c_del: {
				type: Boolean,
				default: false,
			},
			dats: {
				type: Object,
				default: null,
			},

		},
		data() {
			return {
				insert: this.c_insert,
				edit: this.c_edit,
				del: this.c_del,
				i18n: null,
				products: this.dats,
				product: {},
				newid: false,
				search: null,
				options: null,
				option: null,
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				selectedCustomers: null,
				filters: {},
				size: '60vw',
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
			New() {
				this.newid = true;
				this.product = {
					name: null,
					en: null,
					es: null,
					activo: true,
					pages: []
				};
				this.productDialog = true;
			},
			Edit(product) {
				this.newid = false;
				this.product = {...product};
				this.productDialog = true;
			},
			Save() {
				if ((this.product.en == null || this.product.en == '' || this.product.es == null || this.product.es == '')) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else {
					this.product.cass = 1;
					let condition = 'UPD';
					if (this.newid ) { condition = 'INS'; }
					Consult.Process(condition,{
						inf: this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
						if (response.result) {
							this.products = response.result;
							this.productDialog = false;							
						} 
					});
				}
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			Delete() {
				this.product.cass = 1;
				Consult.Process('DEL',{
					inf: 	this.product
				}).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.products = response.result;
						this.deleteProductDialog = false;
					} 				
				});
			},
		}
	}
</script>

<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>